<template>
  <div id="pdf" class="fill-height indigo lighten-5">
    <v-container v-if="loading">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12">
          <span class="text-center text-overline"> Loading... </span>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else-if="error">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>

    <!-- Content -->
    <v-container v-else-if="pdfGroups.length > 0">
      <!-- Hero Header -->
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" class="px-0">
          <!-- Overlay Gradient sheet -->
          <v-sheet id="sheet" class="d-flex align-center rounded-lg">
            <v-container fluid class="pa-5 text-center text-md-left pa-md-16">
              <v-row align="center" justify="start">
                <v-col cols="12" sm="12">
                  <v-chip
                    color="blue darken-2"
                    class="white--text py-6 px-6 rounded-xl"
                  >
                    <h2
                      :class="
                        $vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-h6'
                      "
                    >
                      PlusMinus - Free PDFs
                    </h2>
                  </v-chip>
                </v-col>
              </v-row>

              <v-row align="center" justify="start">
                <v-col cols="12" sm="12" md="10">
                  <h1
                    :class="
                      $vuetify.breakpoint.mdAndUp
                        ? 'text-h2 white--text font-weight-medium'
                        : 'text-h3 white--text font-weight-medium'
                    "
                  >
                    <b> Class PDFs, Notes, Practice Sets &amp; More </b>
                  </h1>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  cols="12"
                  sm="12"
                  md="2"
                >
                  <v-icon
                    class="blue darken-5 rounded-circle pa-1"
                    color="white"
                    size="120"
                  >
                    mdi-download
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="center" justify="start" class="mt-0">
        <v-col cols="12" sm="12" md="5" class="px-0 px-md-3">
          <!-- Premium Pass Ad -->
          <v-card class="card-gradient-2" rounded="0">
            <v-card-text class="white--text">
              <v-chip class="white--text" color="blue darken-3"
                >PlusMinus</v-chip
              >

              <div class="text-h5 mt-2 ml-2">
                Premium Pass
                <v-icon color="amber darken-2" class="ml-2">mdi-crown</v-icon>
              </div>

              <p class="ml-2 mt-1 text-h6 amber--text">
                Unlimited Access to all exams
              </p>

              <div class="d-flex justify-space-between align-center">
                <v-list-item two-line>
                  <v-list-item-content class="white--text">
                    <v-list-item-subtitle class="grey--text text--lighten-2"
                      >Starts for only</v-list-item-subtitle
                    >
                    <v-list-item-title>Rs. 99/month</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <div>
                  <v-btn to="/premium-pass" color="white" elevation="0">
                    Unlock Now
                  </v-btn>

                  <div class="text-center mt-1">
                    <small> Test Series, Mock Tests and More </small>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Google Ad -->
        <v-col cols="12" sm="12" md="7">
          <!-- free-pdf-ad -->
          <Adsense
            data-ad-client="ca-pub-7807573894216192"
            data-ad-slot="4632352999"
            is-new-ads-code="no"
            data-ad-format="auto"
            data-full-width-responsive="yes"
          >
          </Adsense>
        </v-col>
      </v-row>

      <v-row align="center" justify="start">
        <!-- Study Material Heading -->
        <v-col cols="12" sm="12" class="d-flex align-center">
          <div class="mr-2">
            <v-icon size="20" class="mr-2">mdi-file-multiple</v-icon> Study
            Material:
          </div>
          <v-divider></v-divider>
        </v-col>

        <!-- Pdf Groups Looped Cards -->
        <template v-for="(pdfGroupObj, i) in pdfGroups">
          <v-col cols="12" sm="12" md="4" :key="i">
            <v-card class="mx-auto rounded-lg card-gradient" max-width="344">
              <v-card-text class="white--text">
                <h3 class="text-h5 text-transform-capitalize">
                  {{ pdfGroupObj.name }}
                </h3>
                <span class="ml-1">Hin/Eng</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :to="`/free-pdfs/${i}`" text color="white accent-4">
                  View All
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-row>

      <v-divider class="my-9"></v-divider>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Pdf",
  metaInfo: {
    title: "Class PDFs, Notes and Syllabus",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "PlusMinus provide daily class pdfs, notes and other study material for DSSSB exams. Download free DSSSB previous year papers PDF for TGT, PGT, PRT, Jr. Clerk, LDC, DASS Grade 2 and other DSSSB Exams. PDFs for Maths, Reasoning, English, Hindi and Gk for all DSSSB Exams.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "DSSSB Previous Year papers , DSSSB Answer key, DSSSB Notes , GK Notes , Notes PDF , dsssb pdf , dsssb free pdf, free test pdf, free pdf",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "index, follow",
      },
    ],
  },
  components: {},
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
  },
  data: () => ({
    loading: true,
    error: "",
    pdfGroups: [],
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
    },

    setDrawer(value) {
      this.drawer = value;
    },

    fetchPdfGroups() {
      // get courses array from store
      const pdfGroupsArr = this.$store.getters.pdfGroups;

      //if not found in store
      if (pdfGroupsArr.length <= 0 || typeof pdfGroupsArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getPDFDoc")
          .then((res) => {
            if (res) {
              this.pdfGroups = res.pdfGroups;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.pdfGroups = pdfGroupsArr;
        this.setLoading(false);
      }
    },

    fetchPdfs(selectedPdfGroup) {
      // { folder, group, requestType: null }
      var payload = selectedPdfGroup;

      // do not get prev or next if diffrent group/folder
      if (
        this.selectedPdfGroup == null ||
        payload.folder != this.selectedPdfGroup.folder ||
        payload.group != this.selectedPdfGroup.group
      ) {
        payload.requestType = null;
        this.firstAndLastVisible = null;
      }

      this.selectedPdfGroup = selectedPdfGroup;

      switch (payload.requestType) {
        case "prev":
          payload.doc = this.firstAndLastVisible.firstVisible;
          break;
        case "next":
          payload.doc = this.firstAndLastVisible.lastVisible;

          break;
        default:
          payload.requestType = null;
      }

      this.setLoading(true);
      this.$store
        .dispatch("getpdfs", payload)
        .then((res) => {
          // write data if there is data
          if (res.data.length > 0) {
            this.snackbarText = "PDFs fetched successfully :)";
            this.pdfs = res.data;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (payload.requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText = "No PDFs in this folder yet :(";
                this.allQuestions = [];
            }
          }
        })
        .catch((error) => {
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },
  },
  mounted() {
    this.fetchPdfGroups();
  },
};
</script>

<style scoped>
#sheet {
  min-height: 50vh;

  background: #9cecfb; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #0052d4,
    #65c7f7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #0052d4, #65c7f7);
}

.card-gradient {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.card-gradient-2 {
  background: #4776e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #8e54e9,
    #4776e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #8e54e9,
    #4776e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>